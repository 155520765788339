html {
  font-size: 12px
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/theme.scss';

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
    .rtl {
        direction: rtl;
    }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
    display: none;
}


// fix for responsive blog content width
@media (max-width: 1024px) {
    .prose {
     max-width: none !important; 
    }
}

// fix for long URLs going outside of content max-width
.prose {
    word-break: break-word;
}

// fix for backticks before and after inline code
.prose code::before {
    content: unset;
}

.prose code::after {
    content: unset;
}

// styling change for inline code
.prose code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
}

// AOS styles
$aos-distance: 10px;
@import 'node_modules/aos/src/sass/aos.scss';